<template>
  <div id="trainingCourses">
    <CommonTitleStyleAndSearch :searchStatus="searchStatus" :clear="show" @change="searchClick"></CommonTitleStyleAndSearch>
    <div class="common_layout">
      <div class="layout_left">
        <CommonListCom  :show="show" :typeList="typeList" @change="changeClick" @clear="clearClick">
          <h3>共找到 <span>{{ totalNum }}</span> 个课程</h3>
        </CommonListCom>
        <CourseList :dataList="dataList"></CourseList>
        <CommonPagination
        :pageNum="pageNum"
        :pageSize="pageSize"
        :totalNum="totalNum"
        placement="right"></CommonPagination>
      </div>
      <div class="layout_right">
        <CommonInstitutional></CommonInstitutional>
        <template v-if="$route.meta.needType">
          <CommonContribute></CommonContribute>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTitleStyleAndSearch from '@/components/CommonTitleStyleAndSearch.vue'
import CommonPagination from '@/components/CommonPagination.vue'
import CommonContribute from '@/components/CommonContribute.vue'
import CommonInstitutional from '@/components/CommonInstitutional.vue'
import CommonListCom from '@/components/CommonListCom.vue'
import CourseList from './components/CourseList.vue'
import merge from 'webpack-merge'
export default {
  components: {
    CommonTitleStyleAndSearch,
    CommonListCom,
    CommonPagination,
    CommonInstitutional,
    CommonContribute,
    CourseList
  },
  data() {
    return {
      pageNum: parseInt(this.$route.query.pager) || 1,
      pageSize: 15,
      totalNum: 0,
      typeList: [
        { title: '最新发布', type: 1, key: 'publish' },
        { title: '价格', type: 2, key: 'priceSort' },
      ],
      dataList: [],
      search: {},
      searchStatus: false,
      show: false
    }
  },
  mounted() {
    this.showPage();
  },
  methods: {
    showPage() {
      // if(this.searchStatus) return
      // else this.searchStatus = true
      if(this.search.courseName || this.search.priceSort) this.show = true
      else this.show = false
      this.$API.TrainingCoursesList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        courseName: this.search.courseName || '',
        priceSort: parseInt(this.search.priceSort || '') ,
      }).then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.totalNum = res.data.data.total;
          // this.searchStatus = false
        }
      })
    },
    searchClick(val) {
      if(val && val.isTrusted) return 
      this.search.courseName = val
      this.pageNum = 1;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.showPage();
    },
    changeClick(data) {
      this.search.priceSort = null;
      this.search[data.key] = data.type;
      this.pageNum = 1;
      this.show = true;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.showPage();
    },
    clearClick() {
      this.search = {};
      this.pageNum = 1;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.showPage();
    }
  }
}
</script>

<style lang='scss'>
  #trainingCourses {

  }
</style>